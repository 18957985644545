
import { defineComponent, defineAsyncComponent, computed } from "vue";
import { useSubscriptions } from "@/graphql/subscription/subscriptions";
import { dateExpired, subscriptionExpired } from "@/plugins/i18n";
import { CONSTANTS } from "@/graphql/utils/utils";
import { AppSource } from "@/data/global";
import { useSubscriptionUrl } from "@/graphql/subscription/subscription-url";

export default defineComponent({
  name: "Subscription",
  props: ["payment", "createdAt"],
  components: {
    CreateSubscription: defineAsyncComponent(
      () => import("@/components/subscription/CreateSubscription.vue")
    ),
  },
  setup() {
    const source = localStorage.getItem(CONSTANTS.appSource);
    return {
      ...useSubscriptionUrl(),
      ...useSubscriptions(),
      leftDays: computed(() => subscriptionExpired(true) as number),
      appSourceIsMobile:
        source.endsWith(AppSource.ios.name) ||
        source.endsWith(AppSource.android.name),
      dateExpired,
    };
  },
});
