import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-2dfed146"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "p-text-right p-col-12 p-p-0" }
const _hoisted_2 = {
  class: "p-d-flex p-jc-around p-ai-center p-flex-wrap",
  style: {"width":"100%","height":"100%"}
}
const _hoisted_3 = {
  class: "p-p-2",
  style: {"border":"2px dashed var(--bluegray-200)","max-width":"300px"}
}
const _hoisted_4 = { class: "p-text-bold" }
const _hoisted_5 = { class: "p-field-checkbox p-my-2" }
const _hoisted_6 = {
  key: 0,
  style: {"color":"var(--primary-color)"},
  class: "p-ml-1"
}
const _hoisted_7 = {
  key: 1,
  class: "p-error p-ml-1"
}
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }
const _hoisted_10 = { key: 2 }
const _hoisted_11 = {
  key: 1,
  class: "p-text-bold",
  style: {"max-width":"300px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_CreateSubscription = _resolveComponent("CreateSubscription")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("h5", _hoisted_1, _toDisplayString(_ctx.$t("activity.manageSubscription")), 1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.$t("activity.subscribe.current")), 1),
        _createElementVNode("div", _hoisted_5, [
          _createTextVNode(_toDisplayString(_ctx.$t("ticket.status0")) + " : ", 1),
          (_ctx.leftDays > 0)
            ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.$t("activity.subscribe.active")), 1))
            : (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.$t("activity.subscribe.inactive")), 1))
        ]),
        (_ctx.leftDays > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(_ctx.$t("activity.remainDays", { left: _ctx.leftDays })) + " " + _toDisplayString(_ctx.$tm(`activity.days`)[_ctx.leftDays > 1 ? 1 : 0]), 1))
          : (_ctx.leftDays < 0)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.$t("activity.exceededDays", { date: _ctx.$d(_ctx.dateExpired(_ctx.leftDays)) })), 1))
            : (_openBlock(), _createElementBlock("div", _hoisted_10, _toDisplayString(_ctx.$t("activity.expiredToday")), 1))
      ]),
      (!_ctx.appSourceIsMobile)
        ? (_openBlock(), _createBlock(_component_Button, {
            key: 0,
            class: "p-button-info p-my-2 p-button-outlined",
            label: _ctx.$t('activity.subscribe.purchase'),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.purchaseDialog = true)),
            loading: _ctx.purchaseLoading,
            disabled: _ctx.purchaseLoading,
            icon: "pi pi-money-bill"
          }, null, 8, ["label", "loading", "disabled"]))
        : (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(_ctx.$t("activity.iosSubscriptionPayment")), 1))
    ]),
    (_ctx.purchaseDialog)
      ? (_openBlock(), _createBlock(_component_CreateSubscription, {
          key: 0,
          subscriptions: _ctx.subscriptions.slice(1),
          modelValue: _ctx.purchaseDialog,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.purchaseDialog) = $event)),
          subscription: _ctx.subscription,
          "onUpdate:subscription": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.subscription) = $event)),
          onSubmit: _cache[3] || (_cache[3] = ($event: any) => (_ctx.purchase(_ctx.leftDays + 0)))
        }, null, 8, ["subscriptions", "modelValue", "subscription"]))
      : _createCommentVNode("", true)
  ], 64))
}