import { useMutation } from "@vue/apollo-composable";
import {
  PurchaseSubscriptionInput,
  _Subscription,
  MutationSubscriptionUrlArgs,
} from "@/graphql/types";
import { gql } from "@apollo/client";
import { activeActivity } from "@/plugins/i18n";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";
import { reactive, ref } from "vue";
import moment from "moment";

type SubscriptionUrlData = {
  subscriptionUrl: string;
};
const SUBSCRIPTION_URL = gql`
  mutation SubscriptionUrl($input: PurchaseSubscriptionInput!) {
    subscriptionUrl(input: $input)
  }
`;
export const useSubscriptionUrl = () => {
  const toast = useToast();
  const { t } = useI18n();

  const input = reactive<PurchaseSubscriptionInput>({
    subscriptionId: 1,
    activityId: activeActivity.value.id,
    amount: 0,
    transactionId: "",
    currency: "XOF",
    designation: activeActivity.value.name + "_" + activeActivity.value.id,
    expiredAt: "",
  });
  const subscription = ref<_Subscription>(null);
  const purchaseDialog = ref(false);

  const {
    mutate,
    onDone,
    loading: purchaseLoading,
  } = useMutation<SubscriptionUrlData, MutationSubscriptionUrlArgs>(
    SUBSCRIPTION_URL
  );
  onDone(({ data }) => {
    const response = data?.subscriptionUrl || "";
    if (response && !response.includes("error")) {
      window.location.href = response;
    } else {
      console.log(response);
      toast.add({
        severity: "error",
        summary: t("payment.transaction.title"),
        detail: t("activity.paydunya_url_error"),
        life: parseInt(process.env.VUE_APP_TOAST_LIFE),
      });
    }
  });

  function purchase(left: number) {
    input.transactionId = Date.now() + "_" + activeActivity.value.id;
    input.subscriptionId = subscription.value.id;
    input.currency = subscription.value.currency;
    input.amount = subscription.value.cost;
    input.expiredAt = moment()
      .startOf("days")
      .add(subscription.value.duration, "months")
      .add(left > 0 ? left : 0, "days");
    purchaseDialog.value = false;
    void mutate({ input });
  }

  return {
    purchase,
    purchaseLoading,
    purchaseDialog,
    subscription,
    input,
  };
};
